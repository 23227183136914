import React, { createContext } from 'react';
import { PageProps } from 'gatsby';

import SEO from 'src/components/SEO';

// Global styling
import 'src/styles/base.module.css';

// Component classes
import app from './app.module.css';

// Context to pass down page props
export const PagePropsContext = createContext(
    {} as Omit<PageProps<object, IPageContext>, 'children'>,
);

// Root component
const App: React.FC<PageProps<object, IPageContext>> = ({ children, ...pageProps }) => (
    <PagePropsContext.Provider value={pageProps}>
        <SEO />
        <div className={app.appContainer}>
            <main id="main">{children}</main>
        </div>
    </PagePropsContext.Provider>
);

export default App;
