import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface IProps {
    title?: string;
    description?: string;
    card?: string;
    lang?: string;
}

const SEO: React.FC<IProps> = ({ title, description, card, lang }) => {
    const {
        site: { siteMetadata: site },
        file: {
            childImageSharp: {
                fixed: { src: siteCard },
            },
        },
    } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                    siteUrl
                    defaultLang
                }
            }
            file(relativePath: { eq: "card.png" }) {
                childImageSharp {
                    fixed(width: 1200, height: 630, cropFocus: CENTER) {
                        src
                    }
                }
            }
        }
    `);

    const browserTitle = title ?? site.title;
    const pageLang = lang ?? site.defaultLang;
    const pageTitle = title ?? site.title;
    const pageDescription = description ?? site.description;
    const pageImage = card ?? siteCard;
    const pageUrl = `${site.siteUrl}`;

    return (
        <>
            <Helmet>
                <html lang={pageLang} />
                <title>{browserTitle}</title>
                <meta name="description" content={pageDescription} />
                <link rel="home" href={site.siteUrl} />
            </Helmet>

            {pageUrl && (
                <Helmet>
                    <link rel="canonical" href={pageUrl} />

                    {/* Open graph */}
                    <meta property="og:title" content={pageTitle} />
                    <meta property="og:description" content={pageDescription} />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={pageUrl} />
                    <meta property="og:image" content={pageImage} />
                    <meta property="og:image:secure_url" content={pageImage} />
                    <meta property="og:image:type" content="image/png" />
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="630" />
                    <meta property="og:image:alt" content={pageTitle} />

                    {/* Twitter */}
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content={pageTitle} />
                    <meta name="twitter:description" content={pageDescription} />
                    <meta name="twitter:creator" content={site.author} />
                    <meta name="twitter:url" content={pageUrl} />
                </Helmet>
            )}
        </>
    );
};

export default SEO;
