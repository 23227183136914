module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/App/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"osedea_starter_gatsby","short_name":"starter","start_url":"/","background_color":"#fc361a","theme_color":"#fc361a","display":"minimal-ui","icon":"src/assets/icon-512x512.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c208fb3c7bfd389d32c7b47e2a0d1623"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
